<template>
  <v-container class="d-flex flex-column cgu-container">
    <img
      class="logo"
      src="../assets/cot_logo.png"
    />
    <v-card class="d-flex flex-column max-height elevation-24">
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>CGU</v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-html="$t('t.CGUText')"
        class="d-flex flex-column overflow-y"
        ref="cguText"
        @scroll="detectEndScroll"
      >
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="acceptCGU"
          :disabled="!canAccept"
        >{{$t('t.Accept')}}</v-btn>
        <v-btn
          color="primary"
          @click="rejectCGU"
        >{{$t('t.Reject')}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-snackbar
      top
      v-model="showError"
      color="red"
      :timeout="timeout"
    >{{ errorMessage }}</v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: 'cgu',
  data: () => ({
    timeout: 6000,
    errorMessage: null,
    showError: false,
    username: null,
    password: null,
    canAccept: false
  }),
  methods: {
    async acceptCGU () {
      try {
        await this.$store.dispatch('setCurrentUser', { isCguAccepted: true })
        if (this.$route.name === 'mobility-cgu') {
          this.$router.replace({ name: 'mobility-home' })
        } else {
          this.$router.replace({
            path: this.$router.currentRoute.query.redirect || '/'
          })
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.errorMessage = 't.InvalidUserNameOrPassword'
          this.showError = true
        } else {
          this.errorMessage = this.$t('t.InternalError')
          this.showError = true
        }
        console.error(e)
      }
    },
    async rejectCGU () {
      try {
        await this.$store.dispatch('setCurrentUser', { isCguAccepted: false })
        this.$http().logout()
        if (this.$route.name === 'mobility-cgu') {
          this.$router.push({ name: 'mobility-login' })
        } else {
          this.$router.push('/login')
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.errorMessage = 't.InvalidUserNameOrPassword'
          this.showError = true
        } else {
          this.errorMessage = this.$t('t.InternalError')
          this.showError = true
        }
        console.error(e)
      }
    },
    detectEndScroll () {
      const el = this.$refs.cguText
      if (el.offsetHeight + el.scrollTop >= el.scrollHeight - 10) {
        this.canAccept = true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.logo
  max-width 280px
  max-height 200px
  display block
  margin-left auto
  margin-right auto
  margin-bottom 25px
  margin-top 25px

@css {
  .cgu-container {
    height: min(100%, 100vh);
  }
}

.max-height
  max-height calc(100% - 250px)

.overflow-y
  overflow-y auto
</style>
